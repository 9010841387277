import {
  UPDATE_TOKEN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  UPDATE_ALI_TOKEN
} from "./types";

import AuthService from "../services/auth.service";


export const getAliToken = () => (dispatch) => {
  return AuthService.getAliToken().then(
    (response) => {
      console.log(response.data)

      let aliToken=response.data.data
      localStorage.setItem('aliToken', JSON.stringify(aliToken));
      dispatch({
        type: UPDATE_ALI_TOKEN,
        payload: { aliToken: JSON.parse(JSON.stringify(aliToken)) },
      });
      console.log(aliToken)


      return Promise.resolve(response.data);

    },
    (error) => {
      console.log("get aliToken failed:"+error)
      return Promise.reject(error);
    }
  );
};



export const updateToken = (newToken) => (dispatch) => {
  localStorage.setItem('serverToken', JSON.stringify(newToken));
  dispatch({
    type: UPDATE_TOKEN,
    payload: { idToken: JSON.stringify(newToken) },
  });
};


export const checkToken = () => (dispatch) => {
  return AuthService.checkToken().then(
    (response) => {
      /*
      if(response.data.message=="success"){
        return Promise.resolve(response.data);
      }else{
        let newToken=response.data.token
        localStorage.setItem('serverToken', JSON.stringify(newToken));
        dispatch({
          type: UPDATE_TOKEN,
          payload: { idToken: JSON.stringify(newToken) },
        });
        return Promise.resolve(response.data.token);
      }
      */
      console.log(response.data)
      return Promise.resolve(response.data);

    },
    (error) => {
      console.log("checkToken failed:"+error)
      AuthService.logout();
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Login expired",
      });

      return Promise.reject(error);
    }
  );
};
export const emailLogin = (email,password) => (dispatch) => {
  return AuthService.emailLogin(email,password).then(
    (response) => {
      if (response.data.jwttoken) {
        //console.log(response.data.jwttoken)



        let currentUser={displayName: response.data.name, email: email, isReviewer: response.data.isReviewer}
        console.log(currentUser)
        
        localStorage.setItem('serverToken', JSON.stringify(response.data.jwttoken));
        localStorage.setItem('user', JSON.stringify(currentUser));


        var newUser=JSON.parse(JSON.stringify(currentUser))


        dispatch({
          type: LOGIN_SUCCESS,
          payload: { idToken:JSON.stringify(response.data.jwttoken), currentUser:newUser },
        });
        

        dispatch({
          type: SET_MESSAGE,
          payload: "",
        });


        return Promise.resolve(response.data);
      }else{
        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: "Login failed"+error,
        });

        return Promise.reject("failed");
      }

    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Login failed"+error,
      });

      return Promise.reject(error);
    }
  );
};





export const login = (idToken,currentUser) => (dispatch) => {
  return AuthService.tokenLogin(idToken).then(
    (response) => {
      
      if (response.data.token) {
        console.log(response.data.token)
        localStorage.setItem('serverToken', JSON.stringify(response.data.token));
        localStorage.setItem('user', JSON.stringify(currentUser));

        var newUser=JSON.parse(JSON.stringify(currentUser))
        newUser.isReviewer=response.data.reviewer


        dispatch({
          type: LOGIN_SUCCESS,
          payload: { idToken:JSON.stringify(response.data.token), currentUser:newUser },
        });

        return Promise.resolve(response.data);
      }else{
        
        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: ""+response.data,
        });

        return Promise.reject("failed");
      }

    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: ""+error,
      });

      return Promise.reject(error);
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};