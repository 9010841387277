/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import DoctorLayout from "layouts/Doctor.js";

import PrivateRoute from "PrivateRoute.js"

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

ReactDOM.render(

  <Provider store={store}>
  		<Suspense fallback={<div>Loading...</div>}>
	  <BrowserRouter>
	    <Switch>

	      <PrivateRoute
		    path="/doctor/exam/:eid/:enid/:iid"
		    render={(props) => <DoctorLayout {...props}  />} />


	      <PrivateRoute
		    path="/doctor/exam/:eid/:enid"
		    render={(props) => <DoctorLayout {...props}  />} />


	      <PrivateRoute
		    path="/doctor/patient/:pid/:cid"
		    render={(props) => <DoctorLayout {...props}  />} />

		    
	      <PrivateRoute
		    path="/doctor/editPatient/:pid"
		    render={(props) => <DoctorLayout {...props}  />} />


	      <PrivateRoute
		    path="/doctor/review/:eid/:enid/:iid"
		    render={(props) => <DoctorLayout {...props}  />} />


	      <PrivateRoute
		    path="/doctor"
		    render={(props) => <DoctorLayout {...props}  />} />




	      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
	      {/*<Route path="/doctor" render={(props) => <DoctorLayout {...props} />} />*/}
	      <Redirect to="/doctor/dashboard" />
	    </Switch>
	  </BrowserRouter>
	  	</Suspense>
  </Provider>,
  document.getElementById("root")
);
