/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*
import ROLogin from "ROViews/ROLogin";
import RODashboard from "ROViews/Dashboard";

import ROMyPatientsTable from "ROViews/tables/MyPatientsTable";
import ROMyExamsTable from "ROViews/tables/MyExamsTable";
import ROPatientInfoTable from "ROViews/tables/PatientInfoTable";
import ROExamInfoTable from "ROViews/tables/ExamInfoTable";
import ROImageInfoTable from "ROViews/tables/ImageInfoTable";
import ROAllReviewTable from "ROViews/tables/AllReviewTable";
import ROReviewImageTable from "ROViews/tables/ReviewImageTable";

import ROEditPatientForm from "ROViews/forms/EditPatient";
*/

import React from "react";


const ROLogin = React.lazy(() => import('ROViews/ROLogin'));
const RODashboard = React.lazy(() => import('ROViews/Dashboard'));

const ROMyPatientsTable = React.lazy(() => import('ROViews/tables/MyPatientsTable'));
const ROMyExamsTable = React.lazy(() => import('ROViews/tables/MyExamsTable'));
const ROPatientInfoTable = React.lazy(() => import('ROViews/tables/PatientInfoTable'));
const ROExamInfoTable = React.lazy(() => import('ROViews/tables/ExamInfoTable'));
const ROImageInfoTable = React.lazy(() => import('ROViews/tables/ImageInfoTable'));
const ROAllReviewTable = React.lazy(() => import('ROViews/tables/AllReviewTable'));
const ROReviewImageTable = React.lazy(() => import('ROViews/tables/ReviewImageTable'));

const ROEditPatientForm = React.lazy(() => import('ROViews/forms/EditPatient'));

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: RODashboard,
    layout: "/doctor",
  },
  {
    path: "/mypatients",
    name: "My Patients",
    icon: "nc-icon nc-single-copy-04",
    component: ROMyPatientsTable,
    layout: "/doctor",
  },
  {
    path: "/myexams",
    name: "My Exams",
    icon: "nc-icon nc-book-bookmark",
    component: ROMyExamsTable,
    layout: "/doctor",
  },
  {
    path: "/review/:eid/:enid/:iid",
    name: "Review Image",
    icon: "nc-icon nc-book-bookmark",
    component: ROReviewImageTable,
    layout: "/doctor",
  },
  {
    path: "/reviewer",
    name: "Reviewer",
    icon: "nc-icon nc-book-bookmark",
    component: ROAllReviewTable,
    layout: "/doctor",
  },
  {
    path: "/exam/:eid/:enid/:iid",
    name: "Image",
    icon: "nc-icon nc-single-copy-04",
    component: ROImageInfoTable,
    layout: "/doctor",
  },
  {
    path: "/exam/:eid/:enid",
    name: "Exam",
    icon: "nc-icon nc-single-copy-04",
    component: ROExamInfoTable,
    layout: "/doctor",
  },
  {
    path: "/patient/:pid/:cid",
    name: "Patient",
    icon: "nc-icon nc-single-copy-04",
    component: ROPatientInfoTable,
    layout: "/doctor",
  },
  {
    path: "/editPatient/:pid",
    name: "Edit Patient",
    icon: "nc-icon nc-single-copy-04",
    component: ROEditPatientForm,
    layout: "/doctor",
  },
  {
    path: "/login",
    name: "Login",
    component: ROLogin,
    layout: "/auth",
  }
];

export default routes;
