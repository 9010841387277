import axios from 'axios';
import authHeader from './auth-header';


const API_URL = "https://int.cmerrainsoptics.com:5000/api/";

axios.defaults.withCredentials = true

class FbAuthService {
  emailLogin(email,password){
    return axios
      .post(API_URL + 'auth/login', {
        username:email, 
        password:password
      })
  }

  getAliToken(){
    return axios.get(API_URL + 'ali/token', { headers: authHeader() });
  }

  

  logout() {
    localStorage.removeItem('serverToken');
    localStorage.removeItem('user');
    localStorage.removeItem('aliToken');
  }

  checkLogin(){
    return axios.get(API_URL + 'checkLogin');
  }

  
  checkToken(){
    return axios.get(API_URL + 'checkToken', { headers: authHeader(), transformResponse: [data  => data] });
  }

}

export default new FbAuthService();