export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const UPDATE_ALI_TOKEN = "UPDATE_ALI_TOKEN";
