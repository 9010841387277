import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

const PrivateRoute = ({ component: Component = null, render: Render = null, isLoggedIn, ...rest }) => {
  

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          Render ? (
            Render(props)
          ) : Component ? (
            <Component {...props} />
          ) : null
        ) : (
          <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
        )
      }
    />
  );
};


function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  const { aliToken } = state.auth;
  return {
    isLoggedIn,
    message,
    aliToken
  };
}
export default withRouter(connect(mapStateToProps)(PrivateRoute));
