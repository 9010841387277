import {
  UPDATE_TOKEN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_ALI_TOKEN
} from "../actions/types";


const token = JSON.parse(localStorage.getItem('serverToken'));
const aliToken = JSON.parse(localStorage.getItem('aliToken'));
const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user: user, token: token, aliToken: aliToken }
  : { isLoggedIn: false, user: null, token: null, aliToken: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_TOKEN:
      return {
        ...state,
        token: payload.idToken,
      };
    case UPDATE_ALI_TOKEN:
      return {
        ...state,
        aliToken: payload.aliToken,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.currentUser,
        token: payload.idToken,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
      };
    default:
      return state;
  }
}